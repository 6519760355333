<!--http://localhost:8080/#/patentOASummaryRreviewOpinions?caseNumber=gB5L7xvVdm&chatNumber=1830554550600335360&chatId=10-->
<template>
  <div class="patentOASummaryReview">
    <textarea id="copy_content" value="" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"/>
    <div class="myPatentOASummaryReview">

      <!--步骤栏-->
      <div class="pheseBlock">
        <div class="danTitle">
          <span @click="skip('/')">首页</span>
          >
          <span @click="skip('/patentOA')">专利OA</span>
          >
          <span>AI生成</span>
        </div>

        <div class="pheseBlockContent">
          <div class="patentBlockNode" :class="nextPhese > 1 ? 'patentBlockNodeActive' : ''">
            <p>1</p>
            <p class="patentBlockNodeTitle">文件上传</p>
          </div>

          <div class="blockLinkLine" :class="nextPhese > 1 ? 'blockLinkLineActive' : ''"/>

          <div class="patentBlockNode" :class="nextPhese > 2 ? 'patentBlockNodeActive' : ''">
            <p>2</p>
            <p class="patentBlockNodeTitle">总结审查意见</p>
          </div>

          <div class="blockLinkLine" :class="nextPhese > 2 ? 'blockLinkLineActive' : ''"/>

          <div class="patentBlockNode" :class="nextPhese > 3 ? 'patentBlockNodeActive' : ''">
            <p>3</p>
            <p class="patentBlockNodeTitle">提取反驳点</p>
          </div>

          <div class="blockLinkLine" :class="nextPhese > 3 ? 'blockLinkLineActive' : ''"/>

          <div class="patentBlockNode" :class="nextPhese > 4 ? 'patentBlockNodeActive' : ''">
            <p>4</p>
            <p class="patentBlockNodeTitle">生成意见陈述书</p>
          </div>

          <div class="blockLinkLine" :class="nextPhese > 4 ? 'blockLinkLineActive' : ''"/>

          <div class="patentBlockNode" :class="nextPhese > 5 ? 'patentBlockNodeActive' : ''">
            <p>5</p>
            <p class="patentBlockNodeTitle">下载意见陈述书</p>
          </div>
        </div>
      </div>

      <!--页面主体-->
      <div class="OASummaryReviewMainBlock">
        <el-row>
          <el-col class="mainContentBlock" :span="leftBlockSpan">
            <!--nextPhese > 4展示AI生成部分-->
            <div v-if="nextPhese > 4" style="height: 100%;">
              <div class="mainContentBlockTitle">AI生成</div>

              <div class="AIGenerateContent" style="height: calc( 80vh - 40px )">
                <div class="myAiGenerateContent">
                  <div class="AIGenerateContentItem">
                    <p class="AIGenerateItemTitle">
                      审查意见总结
                    </p>
                    <div class="AIGenerateItemContent">
                      <el-skeleton v-if="reviewCommentSummary.content == ''" :rows="3" animated />
                      <v-md-editor
                        v-show="ifShowAllReviewCommentSummary && reviewCommentSummary.content!=''"
                        left-toolbar=""
                        right-toolbar=""
                        mode="edit"
                        v-model="reviewCommentSummary.content"
                        @blur.capture.native = "handleBlur('reviewCommentSummary', 0)"
                      />
                      <div class="AIGenerateItemContentText" v-show="!ifShowAllReviewCommentSummary && reviewCommentSummary.content!=''" v-html="reviewCommentSummary.content.replaceAll('\n\n', '</br></br>')"/>
                    </div>
                    <svg
                      v-if="!ifShowAllReviewCommentSummary"
                      @click="ifShowAllReviewCommentSummary = true"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                      class="expandIcon"
                    >
                      <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10l6 6l6-6l-1.41-1.41z" fill="currentColor"/>
                    </svg>
                    <svg
                      v-else
                      @click="ifShowAllReviewCommentSummary = false"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                      class="expandIcon"
                      style="transform:rotateX(180deg)"
                    >
                      <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10l6 6l6-6l-1.41-1.41z" fill="currentColor"/>
                    </svg>
                  </div>

                  <div v-show="ifShowEditPointer" class="AIGenerateContentItem">
                    <p class="AIGenerateItemTitle">
                      修改点
                    </p>
                    <el-input
                      v-model="editPointer"
                      type="textarea"
                      :autosize="{ minRows: 10, maxRows: 10}"
                      class="AIGenerateContentItemInput"
                    />
                  </div>

                  <div v-for="(item, index) in disTinguishingTechnicalFeatures" class="AIGenerateContentItem">
                    <p class="AIGenerateItemTitle">
                      {{item.title}}
                    </p>
                    <div class="AIGenerateItemContent">
                      <el-skeleton v-if="item.content == ''" :rows="3" animated />
                      <v-md-editor
                        v-else
                        left-toolbar=""
                        right-toolbar=""
                        mode="edit"
                        v-model="item.content"
                        @blur.capture.native = "handleBlur('disTinguishingTechnicalFeatures', index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--nextPhese <= 4展示 文件预览部分-->
            <div v-else style="height: calc( 100% - 40px )">
              <div class="filePreviewTitle">
                <el-dropdown class="myFilePreviewTitle" @command="handleSwitchNowPreviewFile">
                <span class="el-dropdown-link">
                  <span class="myFilePreviewTitleContent">{{ nowPreviewFile.fileName }}</span>
                  <i class="el-icon-arrow-down el-icon--right myFilePreviewTitleIcon"/>
                </span>
                  <el-dropdown-menu slot="dropdown" class="myFilePreviewTitleItemList">
                    <el-dropdown-item
                      v-if="nowPreviewFile != item"
                      v-for="item in fileList"
                      class="myFilePreviewTitleItem"
                      :command="item"
                    >
                      {{ item.fileName }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

              <div class="fileContent">
                <div class="myFileContent">
                  <el-skeleton v-if="nowPreviewContent.content == ''" :rows="3" animated />
                  <v-md-editor
                    v-else
                    left-toolbar=""
                    right-toolbar=""
                    mode="edit"
                    v-model="nowPreviewContent.content"
                    @blur.capture.native="handleBlur('myFileContent', 0)"
                  />
                </div>
              </div>
            </div>
          </el-col>

          <el-col class="mainContentBlock" :span="middleBlockSpan">
            <!--nextPhese > 4展示 意见陈述书部分-->
            <div v-if="nextPhese > 4" style="height: 100%;">
              <div class="mainContentBlockTitle">意见陈述书</div>

              <div class="AIGenerateContent">
                <div id="myAiGenerateContent" class="myAiGenerateContent">
                  <div class="AIGenerateContentItem">
                    <p class="AIGenerateItemTitle">
                      意见陈述书
                    </p>
                    <div class="AIGenerateItemContent">
                      <el-skeleton v-if="statementOfOpinion.content == ''" :rows="3" animated />
                      <v-md-editor
                        v-else
                        left-toolbar=""
                        right-toolbar=""
                        mode="edit"
                        v-model="statementOfOpinion.content"
                        @blur.capture.native = "handleBlur('statementOfOpinion', 0)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="AIGenerateBottomButton">
                <el-button @click="handleStopStep" class="AIGenerateButton2">终止</el-button>
                <el-button :disabled="regenerateDisabled" @click="handleRegenerate"  class="AIGenerateButton2">重新生成</el-button>
                <el-button @click="handleDownloadDocument" :disabled="buttonDisabled" class="AIGenerateButton">下载</el-button>
              </div>
            </div>

            <!--nextPhese <= 4展示 AI生成部分-->
            <div v-else style="height: 100%;">
              <div class="mainContentBlockTitle">AI生成</div>

              <div class="AIGenerateContent">
                <div id="myAiGenerateContent" class="myAiGenerateContent">
                  <div class="AIGenerateContentItem">
                    <p class="AIGenerateItemTitle">
                      审查意见总结
                    </p>
                    <div class="AIGenerateItemContent">
                      <v-md-editor
                        v-show="ifShowAllReviewCommentSummary && reviewCommentSummary.content != ''"
                        left-toolbar=""
                        right-toolbar=""
                        mode="edit"
                        v-model="reviewCommentSummary.content"
                        @blur.capture.native = "handleBlur('reviewCommentSummary', 0)"
                      />
                      <div class="AIGenerateItemContentText" v-show="!ifShowAllReviewCommentSummary && reviewCommentSummary.content!=''" v-html="reviewCommentSummary.content.replaceAll('\n\n', '</br></br>')"/>
                      <el-skeleton v-if="reviewCommentSummary.content == ''" :rows="3" animated />
                    </div>
                    <svg
                      v-if="!ifShowAllReviewCommentSummary"
                      @click="ifShowAllReviewCommentSummary = true"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                      class="expandIcon"
                    >
                      <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10l6 6l6-6l-1.41-1.41z" fill="currentColor"/>
                    </svg>
                    <svg
                      v-else
                      @click="ifShowAllReviewCommentSummary = false"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24"
                      class="expandIcon"
                      style="transform:rotateX(180deg)"
                    >
                      <path d="M16.59 8.59L12 13.17L7.41 8.59L6 10l6 6l6-6l-1.41-1.41z" fill="currentColor"/>
                    </svg>
                  </div>

                  <div v-show="ifShowEditPointer" class="AIGenerateContentItem">
                    <p class="AIGenerateItemTitle">
                      修改点
                    </p>
                    <el-input
                      v-model="editPointer"
                      type="textarea"
                      :autosize="{ minRows: 10, maxRows: 10}"
                      class="AIGenerateContentItemInput"
                    />
                  </div>

                  <div v-for="(item, index) in disTinguishingTechnicalFeatures" class="AIGenerateContentItem">
                    <p class="AIGenerateItemTitle">
                      {{item.title}}
                    </p>
                    <div class="AIGenerateItemContent">
                      <el-skeleton v-if="item.content == ''" :rows="3" animated />
                      <v-md-editor
                        v-else
                        left-toolbar=""
                        right-toolbar=""
                        mode="edit"
                        v-model="item.content"
                        @blur.capture.native = "handleBlur('disTinguishingTechnicalFeatures', index)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="AIGenerateBottomButton">
                <el-button @click="handleStopStep" class="AIGenerateButton2">终止</el-button>
                <el-button :disabled="regenerateDisabled" @click="handleRegenerate" class="AIGenerateButton2">重新生成</el-button>
                <el-button v-if="nextPhese == 3" :disabled="buttonDisabled" @click="handleNextButton" class="AIGenerateButton">下一步</el-button>
                <el-button v-if="nextPhese == 4" :disabled="buttonDisabled" @click="handleGenerateDocument" class="AIGenerateButton">生成意见陈述书</el-button>
              </div>
            </div>

            <!--左侧收缩按钮 1收缩 2展开-->
            <img class="leftButton1" v-if="ifShowLeftBlock" @click="handleRetractButton('left1')" src="../../assets/images/patentOAAi/leftRetract.png"/>
            <div class="leftButton2" v-else @click="handleRetractButton('left2')">OA</div>

            <!--左侧收缩按钮 1收缩 2展开-->
            <img class="rightButton1" v-if="ifShowRightBlock" @click="handleRetractButton('right1')"  src="../../assets/images/patentOAAi/rightRetract.png"></img>
            <div class="rightButton2" v-else @click="handleRetractButton('right2')">AI</div>
          </el-col>

          <el-col class="mainContentBlock coloredBG" :span="rightBlockSpan">
            <div class="AIDialogTitle">智能AI</div>

            <div id="AIDialogueContent" class="AIDialogueContent">
              <el-row v-for="item in dialogList" :class="item.type == 'user' ? 'userDialog' : 'assistantDialog'">
                <!--AI回答样式-->
                <img v-if="item.type=='assistant'" class="dialogIconAssistant" src="../../assets/images/patentOAAi/fishIcon.png"/>
                <div style="position: relative; display: inline-block; max-width: 70%">
                  <div v-if="item.type=='assistant'" class="dialogContentAssistant" v-html="item.content"/>
                  <svg
                    v-if="item.type=='assistant' && item.content != ''"
                    @click="handleCopy(item)"
                    class="AIDialogueCopyIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true" role="img" width="1em"
                    height="18px"
                    viewBox="0 0 24 24"
                  >
                    <path fill="currentColor" d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1 1 0 0 1 3 21l.003-14c0-.552.45-1 1.006-1zM5.002 8L5 20h10V8zM9 6h8v10h2V4H9zm-2 5h6v2H7zm0 4h6v2H7z"/>
                  </svg>
                  <svg
                    v-show="item.type=='assistant' && item.content != '' && item.content !='欢迎使用蓝灯鱼智能OA答复AI对话'"
                    @click="handleChatRegenerate(item)"
                    class="AIDialogueRegenerateIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true" role="img" width="1em"
                    height="18px"
                    viewBox="0 0 24 24"
                  >
                    <path d="M17.65 6.35A7.958 7.958 0 0 0 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" fill="currentColor"/>
                  </svg>
                </div>

                <!--用户提问样式-->
                <div v-if="item.type=='user'" class="dialogContentUser" v-html="item.content"/>
                <img v-if="item.type=='user'" class="dialogIconUser" src="../../assets/images/patentOAAi/userIcon.png"/>
              </el-row>
              <el-button class="myChatStopGenerate" v-if="chatLoading" @click="handleStopMessage">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class=" iconify iconify--ri" width="1em" height="1em" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16M9 9h6v6H9z"/>
                </svg>
                <span>终止生成</span>
              </el-button>
            </div>

            <div class="AIDialogueInput">
              <el-input
                v-model="myAIDialogInput"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3}"
                class="AIDialogInputBlock"
                @keyup.enter.native="handleSendMessage"
              />
              <img @click="handleSendMessage" class="AIDialogInputButton" src="../../assets/images/patentOAAi/inputIcon.png"/>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store";

import axios from 'axios'

export default {
  name: "patentOASummaryRreviewOpinions",
  data:()=>{
    return {
      stopSignal: axios.CancelToken.source(),
      stopSignal2: axios.CancelToken.source(),

      buttonDisabled:true,
      regenerateDisabled:true,
      nextPhese:2,
      ifShowLeftBlock:true,
      ifShowRightBlock:true,
      leftBlockSpan:8,
      middleBlockSpan:8,
      rightBlockSpan:8,

      caseNumber:"",
      chatNumber:"",
      chatId:"",
      nowPreviewFile: {},
      nowPreviewContent:{},
      fileList:[],

      reviewCommentSummary:{
        id:"",
        parentMessageId:"",
        content:""
      },
      ifShowAllReviewCommentSummary:true,
      ifShowEditPointer:false,
      editPointer:"",
      nowSendDisTinguishingTechnicalFeaturesPrompt:0,
      disTinguishingTechnicalFeaturesPrompt:[],
      disTinguishingTechnicalFeatures:[],
      statementOfOpinion:{
        id:"",
        parentMessageId:"",
        content:""
      },

      dialogList:[{
        type:"assistant",
        conversationId:0,
        content:"欢迎使用蓝灯鱼智能OA答复AI对话"
      }],
      myAIDialogInput:"",
      chatLoading:false,
    }
  },
  mounted() {
    this.caseNumber = this.$route.query.caseNumber
    this.chatNumber = this.$route.query.chatNumber
    this.chatId = this.$route.query.chatId
    this.$axios.get('/aigc/app/oa/'+this.chatId)
      .then(response=>{
        this.fileList = response.data.data
        this.nowPreviewFile = response.data.data[0]
        this.nowPreviewContent = JSON.parse(this.nowPreviewFile.fileContent)

        this.nowPreviewContent.content = this.nowPreviewContent.content.replaceAll(/\n+/g, '\n\n').trim()

        let myFileContent = JSON.stringify(this.nowPreviewContent)
        this.nowPreviewFile.fileContent = myFileContent
        let param = {
          id:this.nowPreviewFile.id,
          fileContent:this.nowPreviewFile.fileContent,
          identifier:this.nowPreviewFile.identifier
        }
        this.$axios.put("/aigc/app/file", param)
      })

    store.commit("loadingBool", true)
    this.buttonDisabled = true
    this.regenerateDisabled = true
    this.handleGeneratePatentOA()
  },
  methods:{
    handleCopy(item){
      let stringContent = item.content.replaceAll('<b>',"").replaceAll("</b>","").replaceAll("<br/>", "\n\n");
      //获取要赋值的input的元素
      var inputElement =  document.getElementById("copy_content");
      //给input框赋值
      inputElement.value = stringContent
      //选中input框的内容
      inputElement.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //提示已复制
      this.$message('已复制');
    },
    //重新生成对话
    handleChatRegenerate(item){
      this.dialogList.forEach(myItem=>{
        if (myItem.conversationId == item.conversationId && myItem.type == 'assistant'){
          myItem.content = ""
        }
      })
      this.chatLoading = true
      this.handleGetResponseMessage(item.conversationId)
    },
    skip(item) {
      this.$router.push({path: item})
    },
    handleScrollToButtom(domId){
      this.$nextTick(()=>{
        let dom = document.getElementById(domId)
        dom.scrollTop = dom.scrollHeight - dom.clientHeight
      })
    },

    handleSwitchNowPreviewFile(item){
      this.nowPreviewFile = item
      this.nowPreviewContent = JSON.parse(this.nowPreviewFile.fileContent)

      this.nowPreviewContent.content = this.nowPreviewContent.content.replaceAll(/\n+/g, '\n\n').trim()

      let myFileContent = JSON.stringify(this.nowPreviewContent)
      this.nowPreviewFile.fileContent = myFileContent
      let param = {
        id:this.nowPreviewFile.id,
        fileContent:this.nowPreviewFile.fileContent,
        identifier:this.nowPreviewFile.identifier
      }
      this.$axios.put("/aigc/app/file", param)
    },

    handleRetractButton(type){
      switch (type){
        case 'left1':
          this.ifShowLeftBlock = false
          break;
        case 'left2':
          this.ifShowLeftBlock = true
          break;
        case 'right1':
          this.ifShowRightBlock = false
          break;
        case 'right2':
          this.ifShowRightBlock = true
          break;
        default:
          break
      }

      if (this.ifShowLeftBlock && this.ifShowRightBlock){
        this.leftBlockSpan = 8
        this.middleBlockSpan = 8
        this.rightBlockSpan = 8
      }
      else if (!this.ifShowLeftBlock && !this.ifShowRightBlock){
        this.leftBlockSpan = 0
        this.middleBlockSpan = 24
        this.rightBlockSpan = 0
      }
      else if (this.ifShowLeftBlock){
        this.leftBlockSpan = 12
        this.middleBlockSpan = 12
        this.rightBlockSpan = 0
      }
      else{
        this.leftBlockSpan = 0
        this.middleBlockSpan = 12
        this.rightBlockSpan = 12
      }
    },

    handleBlur(type, index){
      if (type == 'myFileContent' || !this.buttonDisabled){
        let param = {}
        switch (type){
          //修改文件预览
          case "myFileContent":
            let myFileContent = JSON.stringify(this.nowPreviewContent)
            this.nowPreviewFile.fileContent = myFileContent
            param = {
              id:this.nowPreviewFile.id,
              fileContent:this.nowPreviewFile.fileContent,
              identifier:this.nowPreviewFile.identifier
            }
            this.$axios.put("/aigc/app/file", param)
            break
          case "reviewCommentSummary":
            param = {
              parentMessageId:this.reviewCommentSummary.parentMessageId,
              messageId:this.reviewCommentSummary.id,
              content:this.reviewCommentSummary.content,
            }
            this.$axios.put("/aigc/app/oa/message", param)
            break
          case "disTinguishingTechnicalFeatures":
            param = {
              parentMessageId:this.disTinguishingTechnicalFeatures[index].parentMessageId,
              messageId:this.disTinguishingTechnicalFeatures[index].id,
              content:this.disTinguishingTechnicalFeatures[index].title + "\n\n" + this.disTinguishingTechnicalFeatures[index].content,
            }
            this.$axios.put("/aigc/app/oa/message", param)
            break
          case "statementOfOpinion":
            param = {
              parentMessageId:this.statementOfOpinion.parentMessageId,
              messageId:this.statementOfOpinion.id,
              content:this.statementOfOpinion.content,
            }
            this.$axios.put("/aigc/app/oa/message", param)
            break
        }
      }
    },

    handleStopStep(){
      this.stopSignal.cancel()
      this.stopSignal = axios.CancelToken.source()
      this.regenerateDisabled = false
    },

    handleRegenerate(){
      this.regenerateDisabled = true

      if(this.nextPhese == 3){
        //生成审查意见总结
        this.handleGeneratePatentOA()
      }
      else if (this.nextPhese == 4){
        //生成反驳点
        this.handleNextButton()
      }
      else if (this.nextPhese == 5){
        this.handleGenerateDocument()
      }
    },

    handleGeneratePatentOA(){
      this.reviewCommentSummary = {
        id:"",
        parentMessageId:"",
        content:""
      }

      this.nextPhese = 3
      let param = {
        caseNumber: this.caseNumber,
        chatNumber: this.chatNumber,
        chatType: 1,
        prompt: ""
      }
      this.$axios.post("/aigc/app/oa/message", param)
        .then(response=>{
          this.$axios.get(
            "/aigc/app/oa/completions",
            {
              cancelToken:this.stopSignal.token,
              timeout:5*60*1000,
              params:{ conversationId: response.data.data },
              onDownloadProgress:(data)=>{
                store.commit("loadingBool", false)

                let response = data.target.response
                let itemList = ""
                let item = ""
                if (!response.endsWith("}")) {
                  itemList = response.split("{")
                  item = "{" + itemList[itemList.length - 2]
                }
                else {
                  itemList = response.split("{")
                  item = "{" + itemList[itemList.length - 1]
                }

                let myJson = JSON.parse(item)
                this.reviewCommentSummary = {
                  id:myJson.id,
                  parentMessageId:myJson.parentMessageId,
                  content:myJson.content.split('此致')[0].replaceAll("*", "").replaceAll("#", "").replace("尊敬的审查员：", "").replaceAll(/\n+/g, '\n\n').trim(),
                }

                this.handleScrollToButtom('myAiGenerateContent')
              }
            }
          )
            .then(()=>{
              //保存对话
              let myParam = {
                parentMessageId:this.reviewCommentSummary.parentMessageId,
                messageId:this.reviewCommentSummary.id,
                content:this.reviewCommentSummary.content,
              }
              this.$axios.put("/aigc/app/oa/message", myParam)

              this.ifShowEditPointer = true
              this.buttonDisabled = false
              this.regenerateDisabled = false

              this.handleScrollToButtom('myAiGenerateContent')
            })
        })
    },

    handleNextButton(){
      store.commit("loadingBool", true)
      //开始下一阶段
      this.nextPhese = 4
      this.ifShowAllReviewCommentSummary = false
      this.nowSendDisTinguishingTechnicalFeaturesPrompt = 0
      this.disTinguishingTechnicalFeaturesPrompt = []
      this.disTinguishingTechnicalFeatures = []

      let defectList = this.reviewCommentSummary.content.split("\n\n")
      let list = []
      let i = 0
      defectList.forEach((item, index)=>{
        if (index == 0){
          //第一段，判断是否以数字开头，是则加入 否则跳过
          if (item.trim().match("^\\d.*")!=null){
            list[i] = item
          }
        }
        else if (index == defectList.length-1){
          //最后一段，判断是否以数字开头，是则加入 否则跳过
          if (item.trim().match("^\\d.*")!=null){
            i++
            list[i] = item
          }
        }
        else {
          //若以数字开头 则插入，计数加1
          if (item.trim().match("^\\d.*")!=null){
            i++
            list[i] = item
          }
          // 否则，补充到前一个的后面
          else {
            list[i] += item
          }
        }
      })

      list.forEach(item=>{
        if (item && item != ''){
          this.disTinguishingTechnicalFeaturesPrompt.push(item)
        }
      })

      this.buttonDisabled = true
      this.regenerateDisabled = true

      this.generateDefferenceForEach()
    },

    generateDefferenceForEach(){
      if (this.nowSendDisTinguishingTechnicalFeaturesPrompt < this.disTinguishingTechnicalFeaturesPrompt.length){
        let param = {
          caseNumber: this.caseNumber,
          chatNumber: this.chatNumber,
          chatType: "2",
          prompt: this.disTinguishingTechnicalFeaturesPrompt[this.nowSendDisTinguishingTechnicalFeaturesPrompt]
        }

        this.$axios.post("/aigc/app/oa/message", param)
          .then(response=>{
            this.disTinguishingTechnicalFeatures.push({
              id:"",
              parentMessageId:"",
              content:"",
              title:""
            })
            this.handleScrollToButtom('myAiGenerateContent')

            this.$axios.get(
              "/aigc/app/oa/completions",
              {
                cancelToken:this.stopSignal.token,
                timeout:5*60*1000,
                params:{ conversationId: response.data.data },
                onDownloadProgress:(data)=>{
                  store.commit("loadingBool", false)
                  let response = data.target.response
                  let itemList = ""
                  let item = ""
                  if (!response.endsWith("}")) {
                    itemList = response.split("{")
                    item = "{" + itemList[itemList.length - 2]
                  }
                  else {
                    itemList = response.split("{")
                    item = "{" + itemList[itemList.length - 1]
                  }

                  let myJson = JSON.parse(item)

                  let contentText = myJson.content.split('此致')[0].replaceAll("*", "").replaceAll("#", "").replace("尊敬的审查员：", "").replaceAll(/\n+/g, '\n\n').trim()
                  let title = contentText.split("\n\n")[0]
                  let content = contentText.replace(title+"\n\n", "")

                  this.disTinguishingTechnicalFeatures.splice(this.nowSendDisTinguishingTechnicalFeaturesPrompt, 1)
                  this.disTinguishingTechnicalFeatures.push({
                    id:myJson.id,
                    parentMessageId:myJson.parentMessageId,
                    content:content,
                    title:title
                  })

                  this.handleScrollToButtom('myAiGenerateContent')
                }
              }
            )
              .then(()=>{
                setTimeout(()=>{
                  this.nowSendDisTinguishingTechnicalFeaturesPrompt++
                  this.generateDefferenceForEach()
                }, 500)
              })
          })
      }
      else {
        this.buttonDisabled = false
        this.regenerateDisabled = false
        //保存对话
        this.disTinguishingTechnicalFeatures.forEach(item=>{
          let param = {
            parentMessageId:item.parentMessageId,
            messageId:item.id,
            content:item.content,
          }
          this.$axios.put("/aigc/app/oa/message", param)
        })
      }
    },

    handleGenerateDocument(){
      this.statementOfOpinion = {
        id:"",
        parentMessageId:"",
        content:""
      }

      this.buttonDisabled = true
      this.regenerateDisabled = true
      store.commit("loadingBool", true)
      this.nextPhese = 5
      let param = {
        caseNumber: this.caseNumber,
        chatNumber: this.chatNumber,
        chatType: 3,
        prompt: "",
        systemContent:this.editPointer,
      }
      this.$axios.post("/aigc/app/oa/message", param)
        .then(response=>{
          this.$axios.get(
            "/aigc/app/oa/completions",
            {
              cancelToken:this.stopSignal.token,
              timeout:5*60*1000,
              params:{ conversationId: response.data.data },
              onDownloadProgress:(data)=>{
                store.commit("loadingBool", false)

                let response = data.target.response
                let itemList = ""
                let item = ""
                if (!response.endsWith("}")) {
                  itemList = response.split("{")
                  item = "{" + itemList[itemList.length - 2]
                }
                else {
                  itemList = response.split("{")
                  item = "{" + itemList[itemList.length - 1]
                }

                let myJson = JSON.parse(item)
                this.statementOfOpinion = {
                  id:myJson.id,
                  parentMessageId:myJson.parentMessageId,
                  content:myJson.content.split('此致')[0].replaceAll("*", "").replaceAll("#", "").replace("尊敬的审查员：", "").replaceAll(/\n+/g, '\n\n').trim(),
                }

                this.handleScrollToButtom('myAiGenerateContent')
              }
            }
          )
            .then(()=>{
              this.buttonDisabled = false
              this.regenerateDisabled = false
            })
        })
    },

    handleDownloadDocument(){
      store.commit("loadingBool", true)
      this.$axios.get("/aigc/app/oa/download/"+this.caseNumber,{ responseType:'blob', params:{ chatNumber:this.chatNumber} })
        .then(response=>{
          const myDate = new Date()
          const Y = myDate.getFullYear()
          const M = (myDate.getMonth() < 9) ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1
          const D = (myDate.getDate() < 10) ? "0" + myDate.getDate() : myDate.getDate()
          let date = Y + '-' + M + '-' + D
          const content = response.data
          const blob = new Blob([content])
          let fileName = ''
          fileName = '陈述书-' + date + '.docx';

          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink)
            store.commit("loadingBool", false)
          }
        })
    },

    handleStopMessage(){
      this.stopSignal2.cancel()
      this.stopSignal2 = axios.CancelToken.source()
      this.chatLoading = false
    },

    handleSendMessage(){
      let param = {
        caseNumber: this.caseNumber,
        chatNumber: this.chatNumber,
        chatType: 0,
        prompt: this.myAIDialogInput,
      }
      this.$axios.post("/aigc/app/oa/message", param)
        .then(response=>{
          let myConversationId = response.data.data

          this.dialogList.push({
            type:'user',
            conversationId:myConversationId,
            content:this.myAIDialogInput
          })
          this.dialogList.push({
            type:'assistant',
            conversationId:myConversationId,
            content:""
          })

          this.myAIDialogInput = ""
          this.chatLoading = true
          this.handleScrollToButtom('AIDialogueContent')
          this.handleGetResponseMessage(myConversationId)
        })
    },
    handleGetResponseMessage(myConversationId){
      this.$axios.get(
        "/aigc/app/oa/completions",
        {
          cancelToken:this.stopSignal2.token,
          timeout: 5*60*1000,
          params:{ conversationId: myConversationId },
          onDownloadProgress:(data)=>{
            let response = data.target.response
            let itemList = ""
            let item = ""
            if (!response.endsWith("}")) {
              itemList = response.split("{")
              item = "{" + itemList[itemList.length - 2]
            }
            else {
              itemList = response.split("{")
              item = "{" + itemList[itemList.length - 1]
            }

            this.dialogList.forEach(myItem=>{
              if (myItem.conversationId == myConversationId && myItem.type == 'assistant'){
                let text = JSON.parse(item).content.replaceAll(/\n+/g, '\n\n').replaceAll('\n\n', '<br/>')
                text = text.replaceAll("**", "%%%")

                let pattern = /%%%.*?%%%/g
                let arr = text.match(pattern)

                if(arr){
                  arr.forEach(item=>{
                    let targetText = "<b>"+item.replaceAll("%%%", "")+"</b>"
                    text = text.replace(item, targetText)
                  })
                }

                myItem.content = text
              }
            })

            this.handleScrollToButtom('AIDialogueContent')
          }
        }
      )
        .then(()=>{
          this.chatLoading = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.danTitle {
  top: -40px;
  left: -20px;
  height: 30px;
  min-width: 170px;
  line-height: 30px;
  font-size: 14px;
  color: #999999;
  font-weight: 600;
  position: absolute;
  margin-left: 10px;

  &:before {
    position: absolute;
    content: '';
    width: 3px;
    height: 16px;
    top: 8px;
    background: #1a3dbf;
  }

  span {
    margin: 0 2px;

    &:nth-of-type(1) {
      margin-left: 10px;
    }

    &:nth-of-type(3) {
      color: #506bce;
    }
  }

  span:hover {
    cursor: pointer;
    color: #506bce;
  }
}
.patentOASummaryReview{
  margin-top: 90px;
  height: 84.5vh;
  background-color: #ffffff;
  text-align: center;

  .myPatentOASummaryReview{
    display: inline-block;
    height: 99%;
    width: 99%;
    margin: 5px auto;
    padding-top: 50px;
    background-image:-webkit-linear-gradient( 42deg, #E1E7F8 0%, #D4DFFC 54%, #97BAE7 100%);
    border-radius: 8px 8px 8px 8px;

    .pheseBlock{
      vertical-align: top;
      display: inline-block;
      width: 10vw;
      height: 80vh;
      margin: 0 2.5vh;
      position: relative;

      background: rgba(255,255,255,0.4);
      border-radius: 15px 15px 15px 15px;
      border: 2px solid #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);

      .pheseBlockContent{
        margin: 30px;
        height: calc( 100% - 210px );
        border-radius: 15px 15px 15px 15px;
        background-color: #ffffff;

        display: flex;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
        flex-direction: column;
        padding: 50px 0 100px;

        .patentBlockNode{
          position: relative;
          display: inline-block;
          height: 32px;
          width: 32px;
          line-height: 32px;
          font-size: 16px;
          background: #FFFFFF;
          border-radius: 50%;
          border: 1px solid #C9D0D9;
          color: #888888;
          cursor: default;
          box-shadow: #E9F1FC 0 0 0 5px;

          .patentBlockNodeTitle{
            position: absolute;
            top: 40px;
            left: -36px;
            width: 100px;
            display: inline-block;
            color: #888888;
            font-size: 14px;
            line-height: 14px;
            z-index: 5;
          }
        }
        .patentBlockNodeActive{
          color: #E9F1FC;
          background-color: #386FFC;
          border-radius: 50%;
          border: 1px solid #3261D8;

          .patentBlockNodeTitle{
            color: #2D3748;
            font-weight: bold;
          }
        }
        .blockLinkLine{
          position: relative;
          display: inline-block;
          height: 17%;
          width: 10px;
          background-color: #E9F1FC;
        }
        .blockLinkLineActive:after{
          content: '';
          position: absolute;
          top: 30px;
          left: 2px;
          height: 60%;
          width: 6px;
          background-color: #386FFC;
        }
      }
    }

    .OASummaryReviewMainBlock{
      vertical-align: top;
      display: inline-block;
      width: 80vw;

      .mainContentBlock{
        position: relative;
        height: calc( 80vh + 5px );
        background: rgba(255,255,255,0.4);
        border-radius: 15px 15px 15px 15px;
        border: 2px solid #ffffff;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.08);

        .filePreviewTitle{
          position: absolute;
          top: -37px;
          left: 20px;
          display: inline-block;
          border-top-right-radius:8px;
          border-top-left-radius:8px;
          padding: 5px 25px;
          background-color: #B0C2F2;

          .myFilePreviewTitle{
            font-size: 14px;
            line-height: 19px;
            color: #172A6B;
            font-weight: bold;

            .myFilePreviewTitleContent{
              display: inline-block;
              vertical-align: top;
              max-width: 120px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .myFilePreviewTitleIcon{
              color: #172A6B;
              font-weight: bold;
            }
          }
        }
        .fileContent{
          height: 100%;
          width: calc( 100% - 40px );
          margin: 20px;
          background: #FFFFFF;
          border-radius: 12px 12px 12px 12px;
          overflow: hidden;

          .myFileContent{
            height: calc( 100% - 40px );
            margin: 20px 10px 20px 20px;
            overflow-y: scroll;
            scrollbar-width:thin;

            div{
              box-shadow: none;
              background-color: transparent;
            }

            ::v-deep .v-md-textarea-editor pre{
              padding: 0;
            }
            ::v-deep textarea{
              background-color: transparent;
              padding: 0;
            }
            ::v-deep .v-md-editor__toolbar{
              display: none;
            }
          }
        }

        .mainContentBlockTitle{
          position: absolute;
          top: -30px;
          left: 30px;

          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 18px;
          color: #172A6B;
          line-height: 21px;
        }

        .AIGenerateContent{
          height: calc( 72vh - 40px );
          padding: 20px;
          text-align: left;

          .myAiGenerateContent{
            overflow-y: scroll;
            scrollbar-width:none;
            height: 100%;
            padding-right: 1px;

            .AIGenerateContentItem{
              position: relative;
              margin-bottom: 26px;

              .AIGenerateItemTitle{
                font-family: Inter, Inter;
                font-weight: bold;
                font-size: 14px;
                color: #000000;
                line-height: 24px;
                margin-bottom: 10px;
              }
              .AIGenerateItemContent{
                .AIGenerateItemContentText{
                  color: #2c3e50;
                  font-size: 14px;
                  height: 200px;
                  overflow: hidden;
                }

                div{
                  box-shadow: none;
                  background-color: transparent;
                }
                ::v-deep .v-md-textarea-editor pre{
                  padding: 10px;
                }
                ::v-deep textarea{
                  background-color: transparent;
                  padding: 10px;
                  border-radius: 15px;
                }
                ::v-deep .v-md-editor__toolbar{
                  display: none;
                }
              }
              .AIGenerateContentItemInput ::v-deep .el-textarea__inner{
                scrollbar-width: thin;
              }
            }
            .AIGenerateContentItem:last-of-type{
              margin-bottom: 0;
            }
            .expandIcon{
              position: absolute;
              bottom: -26px;
              right: 0;
              height: 20px;
              color: #3567E6;
              padding: 2px 6px;
              background-color: #C2D0F3;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }
        .AIGenerateBottomButton{
          padding-top: 20px;
          height: calc( 8vh - 18px );
          background: rgba(255,255,255,0.4);
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;

          .AIGenerateButton{
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            display: inline-block;
            padding: 10px 60px;
            background-image:-webkit-linear-gradient( 252deg, #2E72D0 0%, #1B427A 100%);
            border-radius: 8px 8px 8px 8px;
          }

          .AIGenerateButton2{
            font-weight: 500;
            font-size: 14px;
            color: #666666;
            display: inline-block;
            padding: 10px;
            border-radius: 8px 8px 8px 8px;
          }
        }

        .AIDialogTitle{
          position: absolute;
          top: -30px;
          left: 30px;

          -webkit-background-clip:text;
          -webkit-text-fill-color:transparent;
          background-image:-webkit-linear-gradient(358.02892004842deg, #0A2A64 0%, #1C5FBC 100%);
          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
        }
        .AIDialogueContent{
          margin: 20px 20px 0;
          width: calc( 100% - 40px );
          height: calc( 100% - 140px );

          overflow-y: scroll;
          scrollbar-width: none;

          .assistantDialog{
            text-align: left;
            padding: 25px 0;
            position: relative;

            .dialogIconAssistant{
              display: inline-block;
              vertical-align: bottom;
              height: 34px;
              width: 34px;
            }
            .dialogContentAssistant{
              display: inline-block;
              vertical-align: bottom;
              padding: 10px;
              margin-left: 10px;
              background-color: #FFFFFF;
              border-radius: 8px;
              min-height: 18px;

              font-family: Roboto, Roboto;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }

            .AIDialogueCopyIcon{
              display: none;
              position: absolute;
              left: 10px;
              bottom: -22px;
            }
            .AIDialogueRegenerateIcon{
              display: none;
              position: absolute;
              left: 30px;
              bottom: -22px;
            }
          }
          .assistantDialog:hover{
            .AIDialogueCopyIcon{
              color: #555555;
              vertical-align: bottom;
              display: inline-block;
              cursor: pointer;
            }
            .AIDialogueRegenerateIcon{
              color: #555555;
              vertical-align: bottom;
              display: inline-block;
              cursor: pointer;
            }
          }
          .userDialog{
            text-align: right;
            margin: 20px 0;

            .dialogContentUser{
              display: inline-block;
              vertical-align: bottom;
              margin-right: 10px;
              padding: 10px;
              text-align: left;
              max-width: 70%;
              background-color: #A2C1FF;
              border-radius: 8px;

              font-family: Roboto, Roboto;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }
            .dialogIconUser{
              display: inline-block;
              vertical-align: bottom;
              height: 34px;
              width: 34px;
            }
          }
        }
        .AIDialogueInput{
          height: 80px;
          padding: 20px 20px;

          .AIDialogInputBlock{
            width: calc( 100% - 70px );
            vertical-align: middle;
          }

          .AIDialogInputBlock ::v-deep .el-textarea__inner{
            scrollbar-width: none;
          }

          .AIDialogInputButton{
            display: inline-block;
            vertical-align: middle;
            height: 40px;
            width: 40px;
            margin: 10px 0 10px 20px;
            cursor: pointer;
          }
        }

        .myChatStopGenerate{
          font-weight: 500;
          font-size: 14px;
          display: inline-block;
          padding: 8px 15px;
          border-radius: 8px 8px 8px 8px;
          span{
            margin-left: 5px;
            vertical-align: middle;
          }
          svg{
            vertical-align: middle;
          }
        }

        .leftButton1{
          position: absolute;
          height: 36px;
          width: 36px;
          left: -18px;
          top: calc( 50% - 18px );
          z-index: 5;
          cursor: pointer;
        }
        .leftButton2{
          position: absolute;
          left: -26px;
          top: calc( 50% - 19px );
          height: 38px;
          width: 24px;
          background-color: #6C80B3;
          color: #ffffff;
          line-height: 38px;
          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 12px;
          border-radius: 8px 0 0 8px;
          cursor: pointer;
        }

        .rightButton1{
          position: absolute;
          height: 36px;
          width: 36px;
          right: -18px;
          top: calc( 50% - 18px );
          z-index: 5;
          cursor: pointer;
        }
        .rightButton2{
          position: absolute;
          right: -26px;
          top: calc( 50% - 19px );
          height: 38px;
          width: 24px;
          background-color: #6C80B3;
          color: #ffffff;
          line-height: 38px;
          font-family: Inter, Inter;
          font-weight: bold;
          font-size: 12px;
          border-radius: 0 8px 8px 0;
          cursor: pointer;
        }
      }
      .coloredBG{
        background-image: url("../../assets/images/patentOAAi/coloredBG.png");
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 100%, 100%;
      }
    }
  }
}

.myFilePreviewTitleItemList{
  .myFilePreviewTitleItem{
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<style lang="scss">
.v-md-textarea-editor pre, .v-md-textarea-editor textarea{
  font-family: inherit!important;
}
</style>
